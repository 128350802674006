/**
 * This is a helper file for the Users component.
 * Purpose of this file is so we won't cram too many JS code
 * in the .vue template files. These are all promise functions
 * used for frontend to backend communication 
 * via ADAPTER in `@/utilities`
 */

import ENUMS from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import ROUTER from '@/router';

import { useSessionStore } from "@/stores/session";
import { useUserStore } from "@/stores/users";
import { Adapter } from '@/utilities/adapter'
import { grpcErrorToString } from "@/utilities/helpers";

const STORE_SESSION = useSessionStore();
const STORE_USER = useUserStore();
const ADAPTER = new Adapter();

export const adminGuard = async () => {
    console.log("Running admin guard...")
    return new Promise(async (resolve, reject) => {
        ADAPTER.activate(STORE_SESSION.traceId)
            .then(async result => {
                if (result.role === ENUMS.Role.ROLE_ADMIN) {
                    console.log("You have the rights to view this page.")
                    resolve(true);
                } else if (result.role === ENUMS.Role.ROLE_USER) {
                    console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020")
                    ROUTER.push({ path: '/integrations' })
                    reject(Error("User is not an admin"))
                }
            })
    });
}

export const getLicense = async () => {
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: "Getting License..."
        })
        ADAPTER.getLicense(STORE_SESSION.traceId)
            .then(license_obj => {
                STORE_USER.updateLicense(license_obj)
                resolve(true)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })

    });
}

export const listUsers = async () => {
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: "Fetching Users..."
        })
        ADAPTER.listUsers(STORE_SESSION.traceId)
            .then(usersObj => {
                console.log({ usersObj })
                STORE_USER.updateUsers([]) // Empty out the users array for a new one
                usersObj.forEach(userObj => {
                    STORE_USER.updateUsers([...STORE_USER.users, userObj])
                });
                console.log(STORE_USER.users)
                resolve(true)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    });
}

export const countAllUsers = async () => {
    return new Promise(async (resolve, reject) => {
        console.log("Counting all users...")
        ADAPTER.listUsers(STORE_SESSION.traceId)
            .then(usersObj => {
                
                STORE_USER.updateUsers([]) // Empty out the users array for a new one
                STORE_USER.count = usersObj.length;
                resolve(true)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    });
}

export const uninviteUser = async (user) => {
    console.log("Uninviting user: ", user)
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: `Uninviting ${user.email.value}...`
        })
        ADAPTER.uninvite(STORE_SESSION.traceId, user)
            .then(res => {
                // show success notification
                STORE_SESSION.setNotification({
                    type: "success",
                    title: "User successfully uninvited!",
                    details: `User ${user.email.value} has been successfully uninvited.`,
                    isShown: true,
                })
                resolve(true)
            })
            .catch(err => {
                // show error notification
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    })
}

export const inviteUsers = async (users) => {
    console.log("Inviting users: ", users)
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: `Inviting users...`
        })

        ADAPTER.inviteUsers(STORE_SESSION.traceId, users)
            .then(usersObj => {

                /**
                 * BEST APPROACH:
                 * Must query the GRPC listUsers endpoint again
                 * for fresh list of users. This is to make sure that UI is in sync.
                 * Also get the license object for updated data.
                 */

                /**
                 * 🐞 BUG: 
                 * There is a delay in the backend in which the udpated and correct number 
                 * of users is not returned properly after the invitation. There is a delay.
                 */

                STORE_SESSION.setNotification({
                    type: "success",
                    title: "Invite Success!",
                    details: `Invitation was successful and the user list will now be updated.`,
                    isShown: true,
                })
                resolve(true)
            })
            .catch(err => {
                // show error notification
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    })
}

export const setSeat = (user, new_seat) => {
    console.log(`Updating seat for user ${user.email.value}..`)
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: `Updating Seat of ${user.email.value}...`
        })
        // set the user's new seat
        ADAPTER.setSeat(STORE_SESSION.traceId, user, new_seat)
            .then(res => {
                STORE_SESSION.setNotification({
                    type: "success",
                    title: "User seat successfully updated!",
                    details: `Seat for ${user.email.value} has been updated successfully.`,
                    isShown: true,
                })
                resolve(true)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    })
}

export const setRole = (user, new_role) => {
    console.log(`Updating role for user ${user.email.value}..`)
    return new Promise(async (resolve, reject) => {
        STORE_SESSION.setToast({
            isShown: true,
            message: `Updating Role of ${user.email.value}...`
        })
        // set the user's new seat
        ADAPTER.setRole(STORE_SESSION.traceId, user, new_role)
            .then(res => {
                STORE_SESSION.setNotification({
                    type: "success",
                    title: "User role successfully updated!",
                    details: `Role for ${user.email.value} has been updated successfully.`,
                    isShown: true,
                })
                resolve(true)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    })
}


export const getTenant = () => {
    console.log(`Getting tenant object..`)
    return new Promise(async (resolve, reject) => {
        // set the user's new seat
        ADAPTER.getTenant(STORE_SESSION.traceId)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                STORE_SESSION.setNotification({
                    type: "error",
                    title: "An Error Occurred",
                    details: `${err.message}. [${grpcErrorToString(err.code)}].`,
                    isShown: true,
                })
                resolve(false)
            })
            .finally(() => {
                STORE_SESSION.setToast({
                    isShown: false
                })
            })
    })
}