<template>
  <table
    class="w-full divide-y divide-gray-200 table-fixed z-0 overflow-visible"
  >
    <thead class="bg-gray-50 overflow-visible">
      <tr class="overflow-visible">
        <!-- Use the same Table Header Column component but pass nothing -->
        <UsersTableHeaderCols
          v-for="(tableHeader, i) in STORE_USER.tableHeaders"
          :key="i"
          :props="{
            name: tableHeader.name,
            isFirst: i == 0 ? true : false,
            isLast: i == STORE_USER.tableHeaders.length - 1,
          }"
        />
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="i in 8" :key="i">
        <td
          class="
            w-96
            whitespace-nowrap
            py-4
            pl-4
            pr-4
            font-medium
            text-gray-900
            sm:pl-6
          "
        >
          <div
            class="bg-gray-100 rounded-md h-5 w-24 py-0.5 animate-pulse"
          ></div>
        </td>

        <td class="whitespace-nowrap p-4 text-sm text-gray-500">
          <div class="bg-gray-100 rounded-md h-4 w-24 py-1 animate-pulse"></div>
        </td>

        <td class="whitespace-nowrap p-4 text-sm text-gray-500">
          <div class="bg-gray-100 rounded-md h-4 w-24 py-1 animate-pulse"></div>
        </td>

        <td class="whitespace-nowrap p-4 text-sm text-gray-500">
          <div class="bg-gray-100 rounded-md h-4 w-24 py-1 animate-pulse"></div>
        </td>

        <td class="whitespace-nowrap p-4 text-sm text-gray-500">
          <div class="bg-gray-100 rounded-md h-4 w-24 py-1 animate-pulse"></div>
        </td>

        <td
          class="
            relative
            whitespace-nowrap
            text-center text-sm
            font-medium
            text-gray-600 text-center
            items-center
          "
        ></td>
      </tr>

      <!-- Add animated dummy data -->
      <!-- <UsersTableDataLoading v-for="i in 8" :key="i" /> -->
    </tbody>
  </table>
</template>

<script setup>
import UsersTableHeaderCols from "./UsersTableHeaderCols.vue";
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();
</script>
