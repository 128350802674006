<template>
    <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 z-20">
        <span class="sr-only">Open options</span>
        <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems :class="[(menuOrientation == 'bottom') ? 'right-0' : 'bottom-8 right-0', 'z-50 origin-bottom-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none']">
        <div class="py-1">
          <!-- 
            NOTE: #74. Display a menu item only when change is allowed (i.e. mutable)
           -->
          <MenuItem v-if="user.mutables.includes(mutable.MUTABLE_ROLE)" v-slot="{ active }">
            <a href="#"
              @click="handleChangeRole(user, (user.role == enums.Role.ROLE_ADMIN) ? enums.Role.ROLE_USER : enums.Role.ROLE_ADMIN)" 
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm flex items-center space-x-1']">
                <IdentificationIcon class="w-4 h-4"/>
                <span>{{(user.role == enums.Role.ROLE_ADMIN) ? 'Set Role as User' : 'Set Role as Admin'}}</span>
              </a>
          </MenuItem>
          <MenuItem v-if="user.mutables.includes(mutable.MUTABLE_SEAT)" v-slot="{ active }">
            <a href="#"
              @click="handleChangeSeat(user, (user.seat == enums.Seat.SEAT_ASSIGNED) ? enums.Seat.SEAT_UNASSIGNED : enums.Seat.SEAT_ASSIGNED)" 
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm flex items-center space-x-1']">
                <TicketIcon class="w-4 h-4"/>
                <span>{{(user.seat == enums.Seat.SEAT_ASSIGNED) ? 'Unassign License' : 'Assign License'}}</span>
              </a>
          </MenuItem>
          <MenuItem v-if="user.mutables.includes(mutable.MUTABLE_INVITE)" v-slot="{ active }">
            <a href="#" 
              @click="confirmUninvite(user)"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm flex items-center space-x-1']">
                <UserRemoveIcon class="w-4 h-4"/>
                <span>Remove User</span>
              </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>

import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import public_pb from "@preava/preava-prevent-api-grpc-web-js/public_pb";
import { setSeat, setRole, getLicense, listUsers } from "../users.helper.js";
import { useSessionStore } from "@/stores/session";
const STORE_SESSION = useSessionStore();

import { 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuItems 
} from "@headlessui/vue";

import {
  QuestionMarkCircleIcon,
  ExternalLinkIcon, DotsVerticalIcon,
} from "@heroicons/vue/outline";

import {
  TicketIcon, 
  IdentificationIcon,
  UserRemoveIcon
} from "@heroicons/vue/outline";

export default {
  components: {
    // Headless Components
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    // Icons
    QuestionMarkCircleIcon,
    ExternalLinkIcon,
    DotsVerticalIcon,
    TicketIcon, 
    IdentificationIcon, 
    UserRemoveIcon
  },
  setup() {
    return {
      enums,
      mutable: public_pb.UserResponse.Mutable
    };
  },
  data() {
    return {
      STORE_SESSION:STORE_SESSION
    };
  },
  props: ["user", "menuOrientation"],
  methods: {
    async handleChangeRole(user, role){
      console.log("Setting role.", user, role)
      let res = await setRole(user, role);
      console.log({res})
      if(res){
        
        await getLicense();
        await listUsers();
        
      }
    },
    async handleChangeSeat(user, seat){
      console.log("Setting license.", user, seat)
      let res = await setSeat(user, seat);
      console.log({res})
      if(res){
        STORE_SESSION.isDiscreet = true
        await getLicense();
        await listUsers();
        STORE_SESSION.isDiscreet = false
      }
    },
    confirmUninvite(user){
      console.log("Confirming uninvite.")
      STORE_SESSION.modalUninvite.isShown = true
      STORE_SESSION.modalUninvite.user = user
    }
  }
};
</script>
