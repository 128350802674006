<template>
<tr class="">

  <!-- Email Data -->
  <td class="w-96 whitespace-nowrap py-4 pl-4 pr-4 font-normal text-gray-900 sm:pl-6">
    <div class="flex items-center space-x-3">
      <div class="w-72">
        <!-- <div class="font-medium text-sm text-gray-900 truncate">
          <span class="text-ellipsis">This is a very long name to test This is a very long name to test </span>
        </div>
        <div class="text-xs font-normal text-gray-500 shrink truncate">
          <span class="text-ellipsis">email@emasdasd.com</span>
        </div> -->
        <div class="font-normal text-sm text-gray-900 truncate">
          <span class="text-ellipsis">{{ user.email.value }}</span>
        </div>
      </div>
    </div>
  </td>

  <!-- Invite Data -->
  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
    <div
      :class="[
        'inline-flex items-center px-3 py-1 rounded-full text-xs font-normal max-w-full truncate bg-black text-white',
        (user.invite == enums.Invite.INVITE_WAITING_FOR_USER_ACTIVATION) ? 'bg-gray-100 text-gray-800' : '',
        (user.invite == enums.Invite.INVITE_USER_ACTIVATION_COMPLETED) ? 'bg-green-100 text-green-800' : '',
      ]"
    >
      <div class="truncate">
        <span v-if="(user.invite == enums.Invite.INVITE_USER_ACTIVATION_COMPLETED)" class="text-ellipsis">Completed</span>
        <span v-else-if="(user.invite == enums.Invite.INVITE_WAITING_FOR_USER_ACTIVATION)" class="text-ellipsis">Waiting</span>
      </div>
    </div>
  </td>

  <!-- Activity Data -->
  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
    <div
      :class="[
        'inline-flex items-center px-3 py-1 rounded-full text-xs font-normal max-w-full truncate bg-black text-white',
        (user.activity == enums.Activity.ACTIVITY_INACTIVE) ? 'bg-gray-100 text-gray-800' : '',
        (user.activity == enums.Activity.ACTIVITY_ACTIVE) ? 'bg-green-100 text-green-800' : '',
      ]"
    >
      <div class="truncate">
        <span class="text-ellipsis">{{ toCapitalizeCase(enumToString(enums.Activity, user.activity).replace('ACTIVITY_','')) }}</span>
      </div>
    </div>
  </td>

  <!-- Role Data -->
  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
    <div
      :class="[
        'inline-flex items-center px-3 py-1 rounded-full text-xs font-normal max-w-full truncate bg-black text-white',
        (user.role == enums.Role.ROLE_ADMIN) ? 'bg-yellow-100 text-yellow-800' : '',
        (user.role == enums.Role.ROLE_USER) ? 'bg-blue-100 text-blue-800' : '',
      ]"
    >
      <div class="truncate">
        <span class="text-ellipsis">{{ toCapitalizeCase(enumToString(enums.Role, user.role).replace('ROLE_','')) }}</span>
      </div>
    </div>
  </td>

  <!-- Seat Data -->
  <td class="whitespace-nowrap p-4 text-sm text-gray-500">
    <div
      :class="[
        'inline-flex items-center px-3 py-1 rounded-full text-xs font-normal max-w-full truncate bg-black text-white',
        (user.seat == enums.Seat.SEAT_UNASSIGNED) ? 'bg-gray-100 text-gray-800' : '',
        (user.seat == enums.Seat.SEAT_ASSIGNED) ? 'bg-purple-100 text-purple-800' : '',
        
      ]"
    >
      <div class="truncate">
        <span class="text-ellipsis">{{ toCapitalizeCase(enumToString(enums.Seat, user.seat).replace('SEAT_','')) }}</span>
      </div>
    </div>
  </td>

  <!-- User Menu -->
  <td class="relative whitespace-nowrap text-center text-sm font-normal text-gray-600 text-center items-center">
  <!-- 
    NOTE: #74. If seat, role and invite is immutable, then there's no point in displaying the UserMenu
    -->
  <UserMenu 
    v-if="!user.mutables.length <= 0"
    :menuOrientation="menuOrientation" 
    :user="user" 
  />
  </td>
</tr>
</template>

<script>

import UserMenu from "./UserMenu.vue";
import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { enumToString } from "@/utilities/helpers";
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();

export default {
  components: {
    UserMenu
  },
  props: [
    "user", 
    "menuOrientation"
  ],
  setup(){
    return {
      STORE_USER: STORE_USER,
      enums, enums,
      enumToString, enumToString
    }
  },
  methods: {
    toCapitalizeCase(s){
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }
  }
    
  
};
</script>
