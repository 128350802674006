<template>
  <tr class="overflow-visible">
    <UsersTableHeaderCols
      v-for="(tableHeader, i) in STORE_USER.tableHeaders" 
      :key="i"
      :props="{
        id: tableHeader.id,
        name: tableHeader.name,
        info: tableHeader.info,
        isFirst: (i == 0) ? true : false,
        isLast: (i == STORE_USER.tableHeaders.length-1),
        canSort: tableHeader.canSort
      }"
    />
  </tr>
</template>

<script setup>
import UsersTableHeaderCols from "./UsersTableHeaderCols.vue";
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();
</script>