<template>
  <TransitionRoot as="template" :show="STORE_SESSION.modalInvite.isShown">
    <Dialog as="div" class="relative z-10" @close="handleClose()">
      <!-- <div class="fixed inset-0" /> -->
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="bg-blue-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">Invite New Users</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-blue-600 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="handleClose()">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        You will be inviting new Users to your organization.<br />
                        You can separate valid email addresses below by space, semicolon or comma.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex-1 py-6 px-4 sm:px-6 ">
                    <!-- Replace with your content -->
                    <div class="mt-0">
                      <!-- <h3 class="text-sm text-gray-700 mb-2">You can separate the emails below by space, semicolon or comma.</h3> -->
                      <form autocomplete="off" method="post" action="">
                        <!--  This solution is going to prevent Chrome and Firefox to offer 
                              autofill and autocomplete for the rest of input fields inside the form. -->
                        <input autocomplete="false" name="hidden" type="text" style="display:none;"> <!-- a hidden input -->
                        <input 
                          v-model="stringInput" 
                          @input="handleChange" 
                          type="text"
                          name="invitees"
                          id="invitees"
                          ref="invitees"
                          maxlength="6500"
                          autocomplete="off"
                          :placeholder="`user1@${STORE_USER.getTenantPrimaryDomain}, user2@${STORE_USER.getTenantPrimaryDomain}, user3@${STORE_USER.getTenantPrimaryDomain}`" 
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                        />
                      </form>
                    </div>
                    <div class="mt-5 space-x-2 space-y-2">
                      <div v-if="emails.length > 0" class="space-x-2 space-y-2 px-2 border-2 border-gray-300 border-dashed pt-2 pb-4 rounded-md mb-4">
                        <button 
                          v-for="email, index in emails" 
                          :key="index" 
                          :class="[
                            (this.areEmailsValid[index]) ? 'text-blue-400 bg-blue-100' : 'text-red-400 bg-red-100' ,
                            (emails.filter( e => e==email).length > 1) ? 'text-yellow-400 bg-yellow-100' : '' ,
                            (index == 0) ? 'ml-2 mt-2' : '' ,
                            'hover:opacity-75 items-center text-ellipsis rounded-full inline-flex rounded-full px-3 py-0.5 text-xs font-semibold leading-5'
                          ]"
                        >
                          {{email}}
                        </button>
                      </div>
                      <div v-else class="px-4 border-2 border-gray-300 border-dashed pt-4 pb-4 rounded-md">
                        <span class="text-xs font-semibold leading-5 ml-1">Start adding emails within {{STORE_USER.getTenantPrimaryDomain}} to invite</span>
                      </div>
                      
                    </div>
                    <!-- /End replace -->
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" @click="handleClose()">
                      Cancel
                    </button>
                    <button 
                      :disabled="emails.length <= 0 || !this.areEmailsValid.every((e) => e === true)"
                      @click="handleAction()"
                      type="button" class="disabled:opacity-25 disabled:cursor-default ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" >
                      Invite
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
          <!-- End of Email Validator -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

import { useSessionStore } from "@/stores/session";
import { useUserStore } from "@/stores/users";
const STORE_SESSION = useSessionStore();
const STORE_USER = useUserStore();

var validator = require("email-validator");

import { inviteUsers, getLicense, listUsers } from "../users.helper.js";

export default {
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XIcon
  },
  data(){
    return {
      emails: [],
      areEmailsValid: [],
      stringInput: "",
      STORE_SESSION: STORE_SESSION, 
      STORE_USER: STORE_USER
    }
  },
  methods: {
    handleClose(){
      STORE_SESSION.modalInvite.isShown = false;
      setTimeout(() => {
        this.emails= [];
        this.areEmailsValid= [];
        this.stringInput= "";
      }, 1000);
    },
    handleChange(){
      // store values here
      this.emails = this.splitEmails(this.stringInput)
      this.areEmailsValid = this.evaluateEmails(this.emails)
      
      // reverse the arrays to display the most recent email
      // at the very beginning
      this.emails.reverse()
      this.areEmailsValid.reverse()
    },
    async handleAction(){
      console.log("Inviting these emails: ", this.emails);
      this.handleClose();
      
      await inviteUsers(this.emails);
      await getLicense();
      await listUsers();
      
    },
    // splits the email string
    splitEmails(string){
      return string
        .split(/[,]|[;]|[\s]+/)     // segregate the emails by terminators via Regex
        .filter(el => el != "" );   // remove empty values after segregation, if any   
    },
    underTenantDomain(email){
      // split the email via @ and get the domain
      // check if it is part of @preava.email
      const res = email.split("@");
      console.log(res[1] + " == " + STORE_USER.getTenantPrimaryDomain)
      return (res[1] == STORE_USER.getTenantPrimaryDomain) ? true : false
    },
    // map out emails if each are valid or not
    evaluateEmails(emails){
      return emails.map( email => (validator.validate(email) && this.underTenantDomain(email)) ? true : false );
    },
    
    
    
  }
};

</script>