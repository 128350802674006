<template>
  <th
    scope="col"
    :class="[
      props.isFirst ? 'w-96 pl-6' : '',
      props.isLast ? 'w-8 sm:px-6' : '',
      !props.isFirst && !props.isLast ? 'w-1/4' : '',
      'px-4 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-visible',
    ]"
  >
    <div class="w-full flex justify-between items-center">
      <!-- Name Only: When resized to small screen -->
      <div class="truncate block xl:hidden">{{ props.name }}</div>

      <!-- Name + Info: On normal large screen -->
      <div class="hidden xl:flex items-center space-x-1">
        <div class="truncate">{{ props.name }}</div>
        <div v-if="props.info">
          <InfoTooltip :message="props.info" />
        </div>
      </div>

      <!-- Selector Icon -->
      <!-- <div
        v-if="props.canSort"
        class="hidden xl:flex items-center space-x-0.5 items-center"
      >
        <div
          @click="handleClickSort(props.id)"
          :class="[
            STORE_USER.sortedBy.id == props.id &&
            STORE_USER.sortedBy.type != 'none'
              ? 'bg-blue-50 text-blue-500'
              : '',
            'hidden xl:block cursor-pointer hover:opacity-75',
          ]"
        >
          <SelectorIcon
            v-if="
              STORE_USER.sortedBy.id == props.id &&
              STORE_USER.sortedBy.type == 'none'
            "
            class="h-4 w-4"
          />
          <SortAscendingIcon
            v-else-if="
              STORE_USER.sortedBy.id == props.id &&
              STORE_USER.sortedBy.type == 'asc'
            "
            class="h-4 w-4"
          />
          <SortDescendingIcon
            v-else-if="
              STORE_USER.sortedBy.id == props.id &&
              STORE_USER.sortedBy.type == 'desc'
            "
            class="h-4 w-4"
          />
          <SelectorIcon v-else class="h-4 w-4" />
        </div>
      </div> -->
    </div>
  </th>
</template>

<script>
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();

import {
  InformationCircleIcon,
  SelectorIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/vue/outline";

import InfoTooltip from "@/components/tooltip/InfoTooltip";

export default {
  props: ["props"],
  components: {
    // Icons
    InformationCircleIcon,
    SelectorIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    // Custom Components
    InfoTooltip, 
  },
  data(){
    return {
      STORE_USER: STORE_USER
    }
  },
   methods: {
    handleClickSort: (id) => {
      /**
       * @TODO Use ENUMS instead of string literals
       */
      var type = "";
      if (STORE_USER.sortedBy.id != id){
        /**
         * If the user is about to sort with a new column,
         * always return asc by default.
         */
        type = "asc";
      }else{
        /**
         * Else, the user is switching order of sort
         * within the same column
         */
        if(STORE_USER.sortedBy.type == 'asc') {
          type = "desc"
        }else if(STORE_USER.sortedBy.type == 'desc') {
          type = "none";
        }else{
          type = "asc";
        }
      }
      
      STORE_USER.updateSortedBy({
        id: id,
        type: type,
      });
      
    }
    
   }
}


</script>
