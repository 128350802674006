import { defineStore } from 'pinia'

export const useUserStore = defineStore("user", {
  state: () => ({
    users: [],
    tenant: null,
    license: {},
    sortedBy: {
      id: 'email',
      type: 'none'
    },
    count: 0,
    tableHeaders: [
      { id:"email", name: "Email Address", info: "", canSort: true },
      { id:"invite", name: "Activation", info: "Indicates whether a user has activated their account by signing in for the first time.", canSort: true },
      { id:"activity", name: "Activity", info: "Indicates when a user has had an email scanned for risks in the last 24 hours.", canSort: true },
      { id:"role", name: "Role", info: "", canSort: true },
      { id:"seat", name: "License", info: "", canSort: true },
      { id:"", name: "", info: "", canSort: false },
    ],
  }),
  getters: {

    getTenantPrimaryDomain(){
      return (this.tenant) ? this.tenant.domainsAuthenticaion.valuesList[0].value : ''
    },
    
    // @TODO convert these strings into ENUMS
    // getSortedUsers will return a sorted list of users vased on `sortedBy`
    getSortedUsers(state) {
      var _users = [...state.users];

      // If there is no sort method selected,
      // show the default users array returned by backend 
      if(state.sortedBy.type == 'none'){
        // console.log("Removing sort for users")
        return state.users
      }

      // else, switch the sort type/method
      switch (state.sortedBy.id) {
        case 'email':
          if(state.sortedBy.type == 'asc'){
            console.log("Sorting via email in asc order")
            return _users.sort((a, b) => {
              let fa = a.email.value.toLowerCase();
              let fb = b.email.value.toLowerCase();
              if (fa < fb) { return -1; }
              if (fa > fb) { return 1; }
              return 0;
            });
          }else if(state.sortedBy.type == 'desc'){
            console.log("Sorting via email in desc order")
            return _users.sort((a, b) => {
              let fa = a.email.value.toLowerCase();
              let fb = b.email.value.toLowerCase();
              if (fa < fb) { return 1; }
              if (fa > fb) { return -1; }
              return 0;
            });
          }

        case 'invite':
          if(state.sortedBy.type == 'asc'){
            console.log("Sorting via invite in asc order")
            return _users.sort((a, b) => a.invite - b.invite );
          }else if(state.sortedBy.type == 'desc'){
            console.log("Sorting via invite in desc order")
            return _users.sort((a, b) => b.invite - a.invite );
          }

        case 'activity':
          if(state.sortedBy.type == 'asc'){
            console.log("Sorting via activity in asc order")
            return _users.sort((a, b) => a.activity - b.activity );
          }else if(state.sortedBy.type == 'desc'){
            console.log("Sorting via activity in desc order")
            return _users.sort((a, b) => b.activity - a.activity );
          }

        case 'seat':
          if(state.sortedBy.type == 'asc'){
            console.log("Sorting via seat in asc order")
            return _users.sort((a, b) => a.seat - b.seat );
          }else if(state.sortedBy.type == 'desc'){
            console.log("Sorting via seat in desc order")
            return _users.sort((a, b) => b.seat - a.seat );
          }

        case 'role':
          if(state.sortedBy.type == 'asc'){
            console.log("Sorting via role in asc order")
            return _users.sort((a, b) => a.role - b.role );
          }else if(state.sortedBy.type == 'desc'){
            console.log("Sorting via role in desc order")
            return _users.sort((a, b) => b.role - a.role );
          }
          
        default:
          return _users
      }

    },
    isOutOfSeats(state){
      return (state.license && state.license.seatsAssigned >= state.license.seatsTotal);
    },
  },
  actions: {
    updateLicense(payload){
      this.license = payload
    },
    updateUsers(payload){
      this.users = payload
    },
    updateSortedBy(payload){
      this.sortedBy = payload
    },
    setTenant(tenant){
      this.tenant = tenant
    }
  },
})