<template>
    <TransitionRoot as="template" :show="STORE_SESSION.modalUninvite.isShown">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="handleClose()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Confirm User Removal
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Are you sure you’d like to remove <span class="font-bold text-gray-700">{{ STORE_SESSION.modalUninvite.user.email.value }}</span> from your organization?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm" @click="handleAction()">
                  Remove User
                </button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="handleClose()" ref="cancelButtonRef">
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
<script>

import { uninviteUser, getLicense, listUsers } from "../users.helper.js";
import { useSessionStore } from "@/stores/session";
const STORE_SESSION = useSessionStore();

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
  TransitionChild, 
  TransitionRoot
} from "@headlessui/vue";
export default {
  components: { 
    Dialog, DialogOverlay, DialogTitle, DialogDescription, TransitionChild, TransitionRoot
  },
  methods: {
    async handleAction(){
      STORE_SESSION.modalUninvite.isShown = false
      await uninviteUser(STORE_SESSION.modalUninvite.user);
      await getLicense();
      await listUsers();
    },
    handleClose(){
      STORE_SESSION.modalUninvite.isShown = false
    }
  },
  setup(){
    return {
      STORE_SESSION: STORE_SESSION
    }
  },
};
</script>
