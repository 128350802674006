<template>

  <div class="px-4 sm:px-6 lg:px-8 mt-6">
    <header class="flex justify-between">
      <div class="w-full">
        <h1 class="sr-only">Users</h1>
        <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">
          Users
        </h1>
        <p class="text-sm font-normal text-gray-600 w-5/6 my-2">
          View user statuses and manage user access, permissions, and licenses.
        </p>
      </div>
    </header>
    <!-- User Stats -->
    <UsersStatus />
    <!-- User Table -->
    <div class="mt-4 flex flex-col transition ease-in-out">
      <div class="-my-2 -mx-4  sm:-mx-6 lg:-mx-8">
        <div class="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
          <div v-if="!STORE_SESSION.isWaiting" class="border border-gray-300 overflow-visible">
            <UsersTable />
          </div>
          <div v-else class="truncate border border-gray-300">
            <UsersTableLoading />
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <UsersFilterModal @close="STORE_SESSION.filterModal.isShown = false" />

  <!-- Default Notification. You can pass a notification object containing the notification details -->
  <UserModalInvite v-if="!STORE_SESSION.isWaiting" />
  <UserModalUninvite v-if="!STORE_SESSION.isWaiting" />
  <DefaultNotification :notification="STORE_SESSION.notification" />
  <ToastNotification v-if="!STORE_SESSION.isDiscreet" :toast="STORE_SESSION.toast" />

</template>

<script setup>
import { adminGuard, getLicense, listUsers, getTenant, countAllUsers } from "./users.helper.js";
import { useSessionStore } from "@/stores/session";
import { useUserStore } from "@/stores/users";

// Default Components
import DefaultHeader from '@/components/header/DefaultHeader'
import DefaultSidebar from '@/components/sidebar/DefaultSidebar'
import DefaultNotification from '@/components/notification/DefaultNotification'
import ToastNotification from '@/components/notification/ToastNotification'

// Users Components
import UsersStatus from './components/UsersStatus'
import UsersTable from './components/UsersTable'
import UsersTableLoading from './components/UsersTableLoading'
import UserModalUninvite from './components/UserModalUninvite'
import UserModalInvite from './components/UserModalInvite.vue'
import UsersFilterModal from './components/UsersFilterModal.vue'

const STORE_SESSION = useSessionStore();
const STORE_USER = useUserStore();

// Initialize the Trace ID for this session
STORE_SESSION.createNewTraceId();
console.log("Trace ID:", STORE_SESSION.traceId);

// Initialize Component
const initialize = async () => {
  STORE_SESSION.isWaiting = true;
  await adminGuard();
  await getLicense();
  let tenant = await getTenant();
  STORE_USER.setTenant(tenant)
  await countAllUsers();
  await listUsers();
  STORE_SESSION.isWaiting = false;
};

initialize();

/**
 * 
 * ---------------------------------------
 * 
 * UsersTableLoading
 *    '---> UsersTableHeader
 *    |     '---> UsersTableHeaderCols // First column, but only shown animation by passing nothing
 *    |     '---> UsersTableHeaderCols // Nth column, but only shown animation by passing nothing
 *    |     '---> UsersTableHeaderCols // Last column, but only shown animation by passing nothing
 *    '---> UsersTableDataLoading // First Row of Data, but shown only animation
 *    '---> UsersTableDataLoading // Nth Row of Data, but shown only animation
 *    '---> UsersTableDataLoading // Last Row of Data, but shown only animation
 * 
 * ---------------------------------------
 * 
 * UsersTable
 *    '---> UsersTableHeader
 *    |     '---> UsersTableHeaderCols // First column
 *    |     '---> UsersTableHeaderCols // Nth column
 *    |     '---> UsersTableHeaderCols // Last column
 *    |
 *    '---> UsersTableData // First Row of Data
 *    '---> UsersTableData // Nth Row of Data
 *    |     '---> UserMenu  // user option menu for Nth user (if applicable)
 *    '---> UsersTableData // Last Row of Data
 * 
* ---------------------------------------
 * 
 * UserModalInvite
 *    '---> emailsFormValidator // Form component to validate emails before invitation will be sent
 * 
 * ---------------------------------------
 * 
 * UserModalUninvite // show to confirm uninvite from user
 * 
 * DefaultNotification // default notification container
 * 
 * ToastNotification // default toast container
 * 
 */
</script>
