import { defineStore } from 'pinia';
import { v4 } from "uuid";

import { getMetadata, enumToString } from "@/utilities/helpers";

import {
  String,
  Limit,
  Date,
  Role,
  Seat,
  Activity,
  Order as ORDER,
} from "@preava/preava-prevent-api-grpc-web-js/filters_pb";

// preava protos
import {
  SearchUserFilters,
  SearchUsersFieldsData,
} from "@preava/preava-prevent-api-grpc-web-js/public_pb";

import { AdminClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";

// import { getApi } from "prv-js-common/endpoints";
// const ENDPOINT = location.host.includes(":8080")
//   ? process.env.VUE_APP_TEST_ENDPOINT
//   : getApi(location.host);

import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();


const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const ENDPOINT = getApi(location.host);

export const useSessionStore = defineStore("session", {
  state: () => ({
    traceId: null,
    eventsSearch: {
      searchQuery: '',
      searchBy: '' // @TODO: Convert this to ENUM
    },
    notification: {},
    toast: {},
    // Users Page
    modalUninvite: {
      isShown: false,
      user: null,
    },
    modalInvite: {
      isShown: false,
      users: null,
    },
    // Event Page
    modalEvent: {
      isShown: false,
      event: null,
    },
    filterModal: {
      isShown: false,
    },
    usersFilter: {},
    // sometimes we don't want to show any status/notifications while 
    // we do background refresh of the user list. We set this 
    // isDiscreet flag to true if needed.
    isDiscreet: false,
    // if the UI is waiting, set this flag
    isWaiting: true
  }),
  actions: {
    // async updateTraceId(payload) {
    //   this.traceId = payload
    // },
    async createNewTraceId() {
      this.traceId = v4()
    },
    async setNotification(payload) {
      this.notification = payload
    },
    async setToast(payload) {
      this.toast = payload
    },
    async fetchUsers(
      { email,
        role,
        seat,
        activity,
        dateStart,
        dateEnd,
        limit,
        order
      }
    ) {

      this.isWaiting = true;

      const metadata = await getMetadata(null, this.traceId);
      const request = new SearchUserFilters();
      const gateway = new AdminClient(ENDPOINT, null, null);

      let date = new Date();
      if (dateStart) {
        date.setFrom(new Timestamp().setSeconds(dateStart));
      }
      if (dateEnd) {
        date.setTo(new Timestamp().setSeconds(dateEnd));
      }

      let data = new SearchUsersFieldsData();

      if (email) data.setEmail(new String().setValue(email));

      if (role == 'any-role') {
        // do nothing for now
      } else {
        data.setRole(new Role().setRole(role));
      }

      if (seat == 'any-seat') {
        // do nothing for now
      } else {
        data.setSeat(new Seat().setSeat(seat));
      }

      if (activity == 'any-activity') {
        // do nothing for now
      } else {
        data.setActivity(new Activity().setActivity(activity));
      }

      if (dateStart && dateEnd) {
        data.setLastActivityDate(date)
      }


      // console.log({ data })

      request.setLimit(new Limit().setValue(limit));
      request.setOrder(order);
      request.setData(data);

      console.log({ request });

      // console.log({ gateway })

      const stream = gateway.searchUsers(request, metadata);

      var _users = [];
      stream.on("data", function (res) {
        let _user = res.toObject().user
        _user.mutables = res.toObject().mutablesList // include the mutables array for processing on new User object later
        _users.push(_user)
      });

      stream.on("error", function (err) {
        let err_data = null;
        console.error(`A telemetry stream error was returned:`, err);
        stream.cancel();
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status
              }&requestId=${encodeURI(
                err_data.requestId
              )}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type
              }&code=${err_data.code}&details=${encodeURI(
                err_data.details
              )}&message=${encodeURI(err_data.message)}`,
          });
        } else {
          // reject(err);
        }
      });

      stream.on("end", () => {
        stream.cancel();
        STORE_USER.updateUsers(_users)
        this.isWaiting = false;
      });

    },
    // updateFilter(payload) {
    //   console.log("TALENT")
    //   // this.filter = { ...this.filter, ...payload };
    // },
  },
})