<template>
  <table v-if="STORE_USER.getSortedUsers.length > 0" class="w-full divide-y divide-gray-200 table-fixed z-0 overflow-visible ">
    <thead class="bg-gray-50 overflow-visible">
      <UsersTableHeader />
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white ">      
      <UsersTableData 
        v-for="(user, i) in STORE_USER.getSortedUsers" 
        :key="user.userId.value" 
        :user="user" 
        :menuOrientation="(i > (STORE_USER.users.length/2) ? 'top' : 'bottom')"
      />
    </tbody>
  </table>
  <div v-else>
    <div class="text-center py-20">
      <svg class="mx-auto h-12 w-12 text-gray-400"
        aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path></svg>

      <h3 class="mt-2 text-sm font-medium text-gray-900">
        No Users Found
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Try modifying the Users filter to see more events.
      </p>
    </div>
  </div>
</template>

<script setup>
import UsersTableHeader from "./UsersTableHeader.vue";
import UsersTableData from "./UsersTableData.vue";
import { useUserStore } from "@/stores/users";
const STORE_USER = useUserStore();
</script>
