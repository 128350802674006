<template>

  <!-- Seat Status -->
  <div :class="[(STORE_USER.isOutOfSeats) ? 'bg-red-100' : 'bg-blue-100' , ' bg-opacity-75 overflow-hidden rounded-lg mt-2']">
    <div class="prv-seat-count">
      <div class="my-2">
        <p :class="[(STORE_USER.isOutOfSeats) ? 'text-red-500' : 'text-blue-500', 'text-sm flex']">
          <CertificateIcon :class="[(STORE_USER.isOutOfSeats) ? 'text-red-500' : 'text-blue-500', 'prv-seat-icon']" />
          <!-- <UsersIcon :class="[(STORE_USER.isOutOfSeats) ? 'text-red-500' : 'text-blue-500', 'prv-seat-icon']" /> -->
          <span class="text-base">Available Licenses</span>
        </p>
        <div v-if="!STORE_SESSION.isWaiting" :class="[(STORE_USER.isOutOfSeats) ? 'text-red-500' : 'text-blue-500', 'prv-count-label flex flex-inline items-center']">
          <p class="ml-1 font-bold">{{ STORE_USER.license.seatsUnassigned }}<span class="mx-2">/</span>{{ STORE_USER.license.seatsTotal }}</p>
        </div>
        <p v-else 
          :class="[
            (STORE_USER.isOutOfSeats) ? 'text-red-500' : 'text-blue-500', 
            'text-lg font-semibold ml-8']
          ">
          Please wait...
        </p>
      </div>
      <span v-if="STORE_USER.isOutOfSeats" class="relative inline-flex">
        <a :disabled="STORE_SESSION.isWaiting" target="_blank" href="https://www.preava.com/contact" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-red-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 disabled:cursor-not-allowed ring-1 ring-red-400 dark:ring-slate-200/20">
          Buy Licenses
        </a>
        <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </span>
      <a v-else :disabled="STORE_SESSION.isWaiting" target="_blank" href="https://www.preava.com/contact" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-blue-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 disabled:cursor-not-allowed ring-1 ring-blue-400 dark:ring-slate-200/20">
        <span>Buy Licenses</span>
      </a>
    </div>
  </div>
  <!-- Results Total / Action Button Group -->
  <div class="overflow-hidden rounded-lg mt-3">
    <div class="h-9 my-2 flex justify-between items-end space-x-4">
      <div class="text-sm font-normal text-gray-600">
        Showing {{ STORE_USER.users.length }} out of {{ STORE_USER.count }} records.
      </div>
      <div class="flex flex-wrap text-right">
        <div class="isolate inline-flex">
          <button
            :disabled="STORE_SESSION.isWaiting"
            @click="handleInviteUsers()"
            type="button"
            :class="[
              'rounded-l-md mr-1',
              `text-blue-600 hover:text-blue-900`,
              'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 space-x-1'
            ]"
          >
            <PlusIcon class="w-4 h-4"/>
            <span>Add Users</span>
          </button>
        </div>
        <div class="isolate inline-flex">
          <button
            :disabled="STORE_SESSION.isWaiting"
            @click="handleRefreshUsers()"
            type="button"
            :class="[
              `-ml-2 text-gray-600 hover:text-gray-900 mr-1`,
              'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 space-x-1'
            ]"
          >
            <RefreshIcon class="w-4 h-4"/>
            <span>Refresh Users</span>
          </button>
        </div>
        <div class="isolate inline-flex">
          <button
            :disabled="STORE_SESSION.isWaiting"
            @click="handleFilterUsers()"
            type="button"
            :class="[
              '-ml-2 rounded-r-md mr-1',
              `text-gray-600 hover:text-gray-900`,
              'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 space-x-1'
            ]"
          >
            <FilterIcon class="w-4 h-4"/>
            <span>Show Filters</span>
          </button>
        </div>
      </div>
    </div>

    
  </div>
  
</template>

<script setup>
// Components
import DefaultUserIcon from '@/assets/icons/DefaultUserIcon';
import { UsersIcon, PlusIcon, RefreshIcon, FilterIcon } from "@heroicons/vue/outline";
import { listUsers, countAllUsers } from "../users.helper.js";
import CertificateIcon from "./CertificateIcon.vue"

// Stores
import { useSessionStore } from "@/stores/session";
import { useUserStore } from "@/stores/users";

const STORE_SESSION = useSessionStore();
const STORE_USER = useUserStore();


var handleInviteUsers = () => {
  console.log("Opening Invite Users component...")
  STORE_SESSION.notification.isShown = false
  STORE_SESSION.modalInvite.isShown = true
};

var handleRefreshUsers = async () => {
  console.log("Refreshing Users list...")
  STORE_SESSION.isWaiting = true;
  await countAllUsers();
  await listUsers();
  STORE_SESSION.isWaiting = false;
};

var handleFilterUsers = async () => {
  console.log("Opening Filter options...")
  STORE_SESSION.filterModal.isShown = true
  // STORE_SESSION.isWaiting = true;
  // await listUsers();
  // STORE_SESSION.isWaiting = false;
  
};
</script>
