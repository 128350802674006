<template>
  <!-- Search Modal -->
  <TransitionRoot as="template" :show="STORE_SESSION.filterModal.isShown">
    <Dialog as="div" class="relative z-20" @close="emit('close')">
      <!-- <div class="fixed inset-0" /> -->
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="
              pointer-events-none
              fixed
              inset-y-0
              right-0
              flex
              max-w-full
              pl-10
              sm:pl-16
            "
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form
                  class="
                    flex
                    h-full
                    flex-col
                    divide-y divide-gray-200
                    bg-white
                    shadow-xl
                  "
                >
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-blue-600 py-6 px-4 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-lg font-medium text-white"
                          >Filter Users</DialogTitle
                        >
                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="
                              rounded-md
                              bg-blue-600
                              text-blue-200
                              hover:text-white
                              focus:outline-none focus:ring-2 focus:ring-white
                            "
                            @click="emit('close')"
                          >
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <!-- <div class="mt-1">
                        <p class="text-sm text-blue-300">
                          Fill up the form below and submit to filter the Users
                          results.
                        </p>
                      </div> -->
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pt-6 pb-5">
                          <div class="flex items-left flex-col">
                            <label
                              for="limits"
                              class="block text-sm font-medium text-gray-900"
                              >Number of Records</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.limit"
                                id="limits"
                                name="limits"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option
                                  v-for="limit in LIMITS"
                                  :key="limit"
                                  :value="limit"
                                >
                                  {{ limit }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="flex items-left flex-col">
                            <label
                              for="order"
                              class="block text-sm font-medium text-gray-900"
                              >Order</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.order"
                                id="order"
                                name="order"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option
                                  v-for="key in Object.keys(ORDERS)"
                                  :key="key"
                                  :value="ORDERS[key]"
                                  :selected="form.order == ORDERS[key]"
                                >
                                  {{ camelCaseString(key) }}
                                </option>
                              </select>
                            </div>
                          </div>

                          

                          <!-- Email -->
                          <div>
                            <label
                              for="filter-email"
                              class="block text-sm font-medium text-gray-900"
                              >Email</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.email"
                                type="text"
                                name="filter-email"
                                id="filter-email"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>

                          <!-- Role -->
                          <div class="flex items-left flex-col">
                            <label
                              for="order"
                              class="block text-sm font-medium text-gray-900"
                              >Role</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.role"
                                id="order"
                                name="order"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option value="any-role">Any</option>
                                <option
                                  v-for="key in Object.keys(ROLES)"
                                  :key="key"
                                  :value="ROLES[key]"
                                  :selected="form.order == ROLES[key]"
                                >
                                  {{ camelCaseString(key).replace("Role ", "") }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <!-- Seat -->
                          <div class="flex items-left flex-col">
                            <label
                              for="order"
                              class="block text-sm font-medium text-gray-900"
                              >License</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.seat"
                                id="order"
                                name="order"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option value="any-seat">Any</option>
                                <option
                                  v-for="key in Object.keys(SEATS)"
                                  :key="key"
                                  :value="SEATS[key]"
                                  :selected="form.order == SEATS[key]"
                                >
                                  {{ camelCaseString(key).replace("Seat ", "") }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <!-- Activity -->
                          <div class="flex items-left flex-col">
                            <label
                              for="order"
                              class="block text-sm font-medium text-gray-900"
                              >Activity</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.activity"
                                id="order"
                                name="order"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option value="any-activity">Any</option>
                                <option
                                  v-for="key in Object.keys(ACTIVITIES)"
                                  :key="key"
                                  :value="ACTIVITIES[key]"
                                  :selected="form.order == ACTIVITIES[key]"
                                >
                                  {{ camelCaseString(key).replace("Activity ", "") }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="isActivityDateEnabled" :class="[isActivityDateEnabled ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">
                              <span aria-hidden="true" :class="[isActivityDateEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 flex items-center">
                              <span class="text-sm font-medium text-gray-900">Enable Activity Date Filter </span><InfoTooltip message="The date range during one or more users have been active." />
                            </SwitchLabel>
                          </SwitchGroup>
                          
                          <!-- Start Date -->
                          <div v-if="isActivityDateEnabled">
                            <label
                              for="start-date"
                              class="block text-sm font-medium text-gray-900"
                              >Start Activity Date</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="dateStartInput"
                                type="date"
                                id="start-date"
                                name="start-date"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>

                          <!-- End Date -->
                          <div v-if="isActivityDateEnabled">
                            <label
                              for="end-date"
                              class="block text-sm font-medium text-gray-900"
                              >End Activity Date</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="dateEndInput"
                                type="date"
                                id="end-date"
                                name="end-date"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>

                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      class="
                        rounded-md
                        border border-gray-300
                        bg-white
                        py-2
                        px-4
                        text-sm
                        font-medium
                        text-gray-700
                        shadow-sm
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-blue-500
                        focus:ring-offset-2
                      "
                      @click="STORE_SESSION.filterModal.isShown = false"
                    >
                      Cancel
                    </button>
                    <button
                      @click="handleSubmitClick()"
                      type="button"
                      class="
                        ml-4
                        inline-flex
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-blue-600
                        py-2
                        px-4
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-blue-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-blue-500
                        focus:ring-offset-2
                      "
                    >
                      Filter
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>

import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import InfoTooltip from "@/components/tooltip/InfoTooltip"
import { defineProps, ref } from "vue";
import moment from "moment";

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'

import { useSessionStore } from "@/stores/session";

import { camelCaseString } from "@/utilities/helpers"

import {
  Order as ORDERS,
} from "@preava/preava-prevent-api-grpc-web-js/filters_pb";

import {
  Role as ROLES,
  Seat as SEATS,
  Activity as ACTIVITIES
} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

const STORE_SESSION = useSessionStore();


const props = defineProps({
  open: Boolean,
});

const emit = defineEmits(['close'])

let isActivityDateEnabled = ref(false)

// enums for date presets
const LIMITS = [50, 100, 500, 1000];

// form inputs to be passed as payload
let form = ref({
  email: '',
  role: 'any-role', // if null, role is not set and all roles will be fetched
  seat: 'any-seat', // if null, seat is not set and all roles will be fetched
  activity: 'any-activity',  // if null, role is not set and all roles will be fetched
  dateStart: moment().startOf("year").unix(), // this will be in unix format for api
  dateEnd: moment().unix(), // this will be in unix format for api
  // dateStart: null,
  // dateEnd: null,
  limit: LIMITS[0], // 50
  order: ORDERS.DESCENDING
});

// these variables are for form date formatting
// set TODAY as default
// let datePreset = ref(DATE_PRESETS.THIS_YEAR);
let dateStartInput = ref(moment.unix(form.value.dateStart).format("YYYY-MM-DD"));
let dateEndInput = ref(moment.unix(form.value.dateEnd).format("YYYY-MM-DD"));

const handleSubmitClick = () => {

  let payload = {...form.value }

  if(isActivityDateEnabled.value){
    payload.dateStart = moment(dateStartInput.value).startOf("day").unix();
    payload.dateEnd = moment(dateEndInput.value).endOf("day").unix();
  }else{
    payload.dateStart = null;
    payload.dateEnd = null;
  }
  
  console.log(payload)

  // STORE_SESSION.updateFilter(payload);
  STORE_SESSION.fetchUsers(payload);

  emit('close')
};


</script>
